/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { getChartDataUser, getCountUser } from 'src/apis/chartAPI'
import ApexChart from 'src/components/Chart/Chartline'
// import ChartArea from 'src/components/Chart/ChartArea'

import CustomDatePickerInput from 'src/components/CustomComponents/CustomDatePickerInput'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { color, formatDate } from 'src/variable/constant'
function Home() {
	const { openError } = useModal()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const [data, setData] = useState({
		categories: [],
		data: [],
	})
	const [date, setDate] = useState({
		start_time: null,
		end_time: null,
	}) as any
	const [users, setUser] = useState(0)
	const handleGetDataUsers = useCallback(async (params = {}) => {
		openLoadingContext()
		try {
			const res = await getChartDataUser({
				type: 'date',
				...params,
			})
			if (res) {
				const { object } = res?.data?.results || []
				const users = object?.map((item) => Number(item.count))
				const date = object?.map((item) => item.date)
				setData({
					data: users,
					categories: date,
				})
			}
			console.log('object', res)
		} catch (error) {
			openError(error)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleCountDataUsers = useCallback(async () => {
		try {
			const res = await getCountUser({})
			if (res) {
				const { object } = res?.data?.results || []

				setUser(object?.users_count || 0)
			}
			console.log('object', res)
		} catch (error) {
			openError(error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleChangeDate = useCallback(
		(key) => (e) => {
			const start_time = dayjs(date.start_time).valueOf()
			const end_time = dayjs(date.end_time).valueOf()
			const current_time = dayjs(e).valueOf()
			if (key === 'start_time' && current_time > end_time)
				return setDate({ end_time: null, [key]: e })
			if (key === 'end_time' && current_time < start_time)
				return setDate({ start_time: null, [key]: e })
			return setDate((pre) => ({ ...pre, [key]: e }))
		},
		[date],
	)
	useEffect(() => {
		handleCountDataUsers()
	}, [])
	useEffect(() => {
		if (date.end_time && date.start_time) {
			const submitDate = {
				start_time: dayjs(date.start_time).format(formatDate),
				end_time: dayjs(date.end_time).format(formatDate),
			}
			handleGetDataUsers(submitDate)
		}
		if (!date.end_time && !date.start_time) handleGetDataUsers()
	}, [date])
	return (
		<Stack
			sx={{
				width: '100%',
			}}
		>
			<Stack
				spacing={0}
				sx={{
					minHeight: 134.812,
					justifyContent: 'space-around',
					position: 'relative',
					top: 'calc(-50px - 16px)',
					left: -16,
					paddingLeft: 30,
					background: 'white',
					width: 'calc(100% + 32px)',
					'@media (max-width:900px)': {
						display: 'none',
					},
				}}
			>
				<Text color={color.Orange} sx={{ fontWeight: 700, fontSize: 30 }}>
					UniVini
				</Text>
				<Text color={color.Green} sx={{ fontWeight: 700, fontSize: 25 }}>
					Exchange Languages, Build Networks and Travel
				</Text>
				<Text color={color.Blue} sx={{ fontWeight: 700, fontSize: 27 }}>
					Users: {users.toLocaleString('en-US')}
				</Text>
			</Stack>

			<Flex
				gap={16}
				sx={{
					maxWidth: '400px',
					marginTop: -60,
					'@media (max-width:900px)': {
						marginTop: 0,
					},
				}}
			>
				<CustomDatePickerInput
					clearable
					label="Start date"
					valueFormat={formatDate}
					value={date.start_time}
					maxDate={new Date()}
					onChange={handleChangeDate('start_time')}
				/>
				<CustomDatePickerInput
					clearable
					label="End date"
					valueFormat={formatDate}
					value={date.end_time}
					maxDate={new Date()}
					onChange={handleChangeDate('end_time')}
				/>
			</Flex>
			<ApexChart {...data} />
			{/* <ChartArea /> */}
		</Stack>
	)
}

export default Home
