import {
	IconAdjustmentsCheck,
	IconArticle,
	IconBellFilled,
	IconBrandIntercom,
	IconCategoryFilled,
	IconContract,
	IconFileSettings,
	IconLabel,
	IconLayersSelected,
	IconNetwork,
	IconSectionFilled,
	IconSettings,
	IconUsers,
} from '@tabler/icons-react'
import { FaChartPie, FaHouseUser } from 'react-icons/fa'
import { FaChartColumn, FaPeopleGroup, FaPerson } from 'react-icons/fa6'
import { pathUrl } from 'src/variable/constant'

const style = { height: 20, width: 20 }
const nav = [
	{
		name: 'Home', // Tên của dropdown
		icon: <FaChartColumn style={{ ...style }} />,
		children: [
			{
				name: 'Dashboard',
				to: '/dashboard',
				icon: <FaChartPie style={{ ...style }} />,
			},
			{
				name: 'User of Province',
				to: pathUrl.province,
				icon: <IconUsers style={{ ...style }} />,
			},
		],
	},
	{
		name: 'Account', // Tên của dropdown
		icon: <FaHouseUser style={{ ...style }} />,
		children: [
			{
				name: 'Guider',
				to: '/guiders',
				icon: <FaPeopleGroup style={{ ...style }} />,
			},
			{
				name: 'User',
				to: '/users',
				icon: <FaPerson style={{ ...style }} />,
			},
		],
	},
	{
		name: 'OTP', // Tên của dropdown
		icon: <IconAdjustmentsCheck style={{ ...style }} />,
		children: [
			{
				name: 'Config OTP', // Tên của dropdown
				icon: <IconFileSettings style={{ ...style }} />,
				to: pathUrl.configphone,
			},
			{
				name: 'Report OTP', // Tên của dropdown
				icon: <IconBrandIntercom style={{ ...style }} />,
				to: pathUrl.otpTracking,
			},
		],
	},
	{
		name: 'Category', // Tên của dropdown
		icon: <IconLayersSelected style={{ ...style }} />,
		children: [
			{
				name: 'Trails', // Tên của dropdown
				icon: <IconCategoryFilled style={{ ...style }} />,
				to: '/categorytrails',
			},
			{
				name: 'Conversation', // Tên của dropdown
				icon: <IconContract style={{ ...style }} />,
				to: pathUrl.conversation,
			},
		],
	},

	{
		name: 'Topic discuss', // Tên của dropdown
		icon: <IconArticle style={{ ...style }} />,
		to: pathUrl.topics,
	},
	{
		name: 'Notification', // Tên của dropdown
		icon: <IconBellFilled style={{ ...style }} />,
		to: pathUrl.notification,
	},
	{
		name: 'Services', // Tên của dropdown
		icon: <IconSectionFilled style={{ ...style }} />,
		to: pathUrl.service,
	},
	{
		name: 'Networks', // Tên của dropdown
		icon: <IconNetwork style={{ ...style }} />,
		to: pathUrl.network,
	},

	{
		name: 'Settings', // Tên của dropdown
		icon: <IconSettings style={{ ...style }} />,
		to: '/settings',
	},
	{
		name: 'Landing Page', // Tên của dropdown
		icon: <IconLabel style={{ ...style }} />,
		to: '/',
	},
]
export default nav
